<template>
    <div class='news_detail'>
        <h3>{{title}}</h3>
        <p>{{time}}</p>
        <div v-html="content"/>
    </div>
</template>
<script lang='ts'>
import { reactive, toRefs } from 'vue'
import { newsDetail } from '../../utils/api'
import { getQueryStr, formatTime} from '../../utils/util'
export default {
    name: '',
    setup() {
        const state= reactive({
            content: '',
            title: '',
            time: ''
        })
        const id = getQueryStr('id') || 702
        async function  loadData() {
            try {
                const result = await newsDetail(id)
                if (result.code == 0) {
                    console.log(result)
                    state.content = result.data.content
                    state.title = result.data.fullTitle
                    state.time = formatTime(result.data.publishTime)
                }
            } catch(e) {
                console.error(e)
            }
        }
        loadData()
        return {
            ...toRefs(state)
        }
    },
}
</script>
<style lang='scss' scoped>
.news_detail {
    padding: $margin-big;
    h3 {
        font-size: $font-max;
        text-align: center;
    }
    &>p {
        text-align: center;
        padding: 10px 10px 15px 0;
        font-size: $font-small;
    }
    img {
        max-width: 100% !important;
    }
    word-break: break-all;
}
</style>